import * as React from "react";
import Svg, {Path} from "react-native-svg";
import {IconProps, DISABLED_COLOR} from ".";

import colors from "../colors.json";

export default function DiagonalArrow(props: IconProps) {
  const color = props.disabled ? DISABLED_COLOR : props.color;
  return (
    <Svg height={props.size} width={props.size} viewBox="0 0 10 10" fill="none">
      <Path
        d="M9.25558 0.770649C9.25135 0.766138 9.24703 0.761678 9.24262 0.757271C9.23822 0.752865 9.23376 0.748545 9.22924 0.744312C8.96111 0.483026 8.60137 0.336483 8.22654 0.336382L8.22645 0.336382L1.57977 0.3356C1.26231 0.335562 1.00499 0.592882 1.00503 0.91034C1.00506 1.2278 1.26245 1.48518 1.5799 1.48522L7.70096 1.48594L0.757246 8.42965C0.532796 8.6541 0.532838 9.01805 0.757341 9.24255C0.981844 9.46705 1.34579 9.4671 1.57024 9.24265L8.51396 2.29893L8.51468 8.41999C8.51471 8.73745 8.7721 8.99483 9.08955 8.99487C9.40701 8.9949 9.66433 8.73758 9.66429 8.42013L9.66351 1.77344L9.66351 1.77335C9.66341 1.39852 9.51687 1.03879 9.25558 0.770649Z"
        fill={color || colors.gray[800]}
      />
    </Svg>
  );
}
