import * as React from "react";
import Svg, {Path} from "react-native-svg";
import {IconProps, DISABLED_COLOR} from ".";

import colors from "../colors.json";

export default function Phone(props: IconProps) {
  const color = props.disabled ? DISABLED_COLOR : props.color;
  return (
    <Svg height={props.size} width={props.size} viewBox="0 0 24 24" fill="none">
      <Path
        d="M6.12894 3.59578L7.28693 3.4674C8.23568 3.36222 9.12652 3.94217 9.41427 4.85233L10.1794 7.27249C10.4378 8.08965 10.1467 8.98059 9.45584 9.4877L9.38162 9.54218C8.6978 10.0441 8.38955 10.9316 8.72032 11.7127C9.92182 14.5499 11.7053 15.7283 12.9098 16.0045C13.3686 16.1097 13.8103 15.8746 14.1351 15.5339L14.4647 15.1881C15.1248 14.4958 16.1827 14.3678 16.9889 14.8827L18.3297 15.7391C19.0545 16.2021 19.4022 17.0766 19.1931 17.9109L18.9721 18.7925C18.8494 19.2818 18.5464 19.7066 18.1238 19.982L17.7502 20.2254C17.5287 20.3696 17.2826 20.4729 17.0195 20.4981C15.1459 20.6776 11.3034 20.0669 8.16394 16.2982C5.08106 12.5975 4.32756 7.92158 4.31945 5.52848C4.31806 5.12002 4.47259 4.73111 4.7265 4.41115L4.78267 4.34036C5.1142 3.9226 5.59885 3.65455 6.12894 3.59578Z"
        stroke={color || colors.gray[800]}
        strokeLinejoin="round"
      />
    </Svg>
  );
}
