import * as React from "react";
import {View, StyleProp, ViewStyle} from "react-native";
import {tailwind} from "../styles";

type props = {
  children: React.ReactNode;
  style?: StyleProp<ViewStyle>;
  testID?: string;
};
export default function Tile({children, style, testID}: props) {
  return (
    <View
      testID={testID}
      style={[
        tailwind("bg-white rounded-lg shadow shadow-size-s flex-row flex-1 items-center"),
        style,
      ]}
    >
      {children}
    </View>
  );
}
