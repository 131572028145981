import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  FieldPolicy,
  gql,
  ApolloLink,
  from,
} from "@apollo/client";
import {RetryLink} from "@apollo/client/link/retry";
import {createFetcher} from "./foundations/fetcher";
import instrospection from "./__generated__/possible-types";
import {TypedTypePolicies} from "../types/__generated__/type-policies";
import {Connection} from "src/types/__generated__/graphql";
import * as Sentry from "@sentry/browser";

const httpLink = createHttpLink({
  fetch: createFetcher(
    process.env.NEXT_PUBLIC_GRAPHQL_URI || "http://graphql.api.carbonhealth.com/graphql",
  ),
});

// Debug info to be sent with Sentry issues
type SentryContext = {
  organizationId?: string;
  organizationAdminId?: string;
};
const sentryContext: SentryContext = {};

const sentryContextLink = new ApolloLink((operation, forward) => {
  // Set up debug info for Sentry issues
  return forward(operation).map(response => {
    let contextChanged = false;

    const organizationId = response?.data?.me?.organization?.id;
    if (organizationId && sentryContext.organizationId !== organizationId) {
      sentryContext.organizationId = organizationId;
      contextChanged = true;
    }

    const organizationAdminId = response?.data?.me?.id;
    if (organizationAdminId && sentryContext.organizationAdminId !== organizationAdminId) {
      sentryContext.organizationAdminId = organizationAdminId;
      contextChanged = true;
    }

    if (contextChanged) {
      Sentry.setContext("App Context", sentryContext);
    }
    return response;
  });
});

// Retry link used to retry network requests upon network failure
// https://www.apollographql.com/docs/react/api/link/apollo-link-retry/
const retryLink = new RetryLink({
  delay: {
    initial: 100,
    max: 2000,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: error => !!error,
  },
});

const typePolicies: TypedTypePolicies = {
  Organization: {
    fields: {
      groups: carbonPagination(),
      members: carbonPagination(["query", "status", "groupId"]),
    },
  },
};

export const createCache = () =>
  new InMemoryCache({possibleTypes: instrospection.possibleTypes, typePolicies});

export const client = new ApolloClient({
  link: from([retryLink, sentryContextLink, httpLink]),
  cache: createCache(),
});

/**
 *
 * The schema for Carbon pagination is designed to support windowed pagination and inifinite scroll
 * using Connections on the server. The current need for pagination in the UI is only windowed
 * so we haven't implemented merging of nodes yet. This wrapper function allows us the ability
 * to add in support for inifinte pagination if / when it is needed by writting custom merge and read
 * functions. These will look a lot like the relayPagination, just merging nodes instead of edges
 * since we don't have a need for edges yet.
 *
 * NOTE, we currently do not support reading from the cache as a user navigates to different pages. This
 * is intentional to
 *   1) ensure up to date data on lists
 *   2) treat paging as an intentional "refecth" event
 *   3) simplify the slicing of data on the client side
 *
 * In order to support client side slicing, we will need the edges { cursor } implementation and a way
 * to fetch surrounding cursors when skipping a page. This works for infinite scroll but not for windowed
 * pagination.
 *
 * https://github.com/apollographql/apollo-client/blob/d3a74ec87cee2b9819a7bb5f2be605c14857aa28/src/utilities/policies/pagination.ts#L91
 *
 * @param keyArgs a FieldPolicy. Normally the fields used in the query which aren't part of the connection arguments
 *
 */
export function carbonPagination(
  keyArgs: FieldPolicy<unknown>["keyArgs"] = false,
): FieldPolicy<Connection> {
  return {
    keyArgs,
    merge: true,
  };
}

export const WindowedPaginationFragment = gql`
  fragment WindowedPagination on Connection {
    pageInfo {
      currentPageNumber
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
      nearbyPages {
        cursor
        pageNumber
      }
      lastPage {
        cursor
        pageNumber
      }
      firstPage {
        cursor
        pageNumber
      }
    }
  }
`;
