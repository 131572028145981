import {ValueOf} from "../types";

export const apiTokenV2StorageKey = "apiTokenV2";
export const currentUserIdStorageKey = "currentUserId";
export const trustedDeviceCodesKey = "trustedDeviceCodes";

export const userSettings = "@ch/userSettings";

export const CarbonHeaders = {
  Authorization: "authorization",
  CarbonAppType: "carbonapptype",
  CarbonPlatformType: "carbonplatformtype",
  CarbonAppVersion: "carbonappversion",
  CarbonDeviceId: "carbondeviceid",
  CarbonVisitId: "carbonvisitid",
  CarbonVisitorId: "carbonvisitorid",
} as const;

export type CarbonHeader = ValueOf<typeof CarbonHeaders>;

export const CustomHttpStatus = {
  NEED_TWO_FACTOR_AUTH: 250,
} as const;

export const ClientPlatforms = {
  ios: "ios",
  android: "android",
  web: "web",
  ssr: "ssr",
} as const;

export type ClientPlatform = ValueOf<typeof ClientPlatforms>;

export const clientPlatformLabels: Record<string, string> = {
  [ClientPlatforms.ios]: "iOS",
  [ClientPlatforms.android]: "Android",
  [ClientPlatforms.web]: "Web",
};

export const clientPlatformIcons: {[key: string]: string} = {
  [ClientPlatforms.ios]: "logo-apple",
  [ClientPlatforms.android]: "logo-android",
  [ClientPlatforms.web]: "ion-ios-cloud",
};
