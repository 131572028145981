import mapValues from "lodash/mapValues";

const extensions = {
  tee<T, U>(this: Promise<T>, f: (x: T) => U): Promise<T> {
    return this.then(arg => Promise.resolve(f(arg)).then(() => arg));
  },
};

// eslint-disable-next-line no-extend-native
Object.defineProperties(
  Promise.prototype,
  mapValues(extensions, f => ({
    enumerable: false,
    writable: true,
    configurable: true,
    value: f,
  })),
);

declare global {
  // eslint-disable-next-line
  interface Promise<T> {
    tee<T, U>(f: (x: T) => U): Promise<T>;

    teeCatch<T, U, V>(f: (x: U) => V): Promise<T>;

    wait<T>(millis: number): Promise<T>;
  }
}
