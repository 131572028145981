import * as React from "react";
import Svg, {Path} from "react-native-svg";
import {IconProps, DISABLED_COLOR} from ".";

import colors from "../colors.json";

export default function EyeClosedIcon(props: IconProps) {
  const color = (props.disabled ? DISABLED_COLOR : props.color) || colors.gray[200];
  return (
    <Svg width={props.size} height={props.size} viewBox="0 0 24 24" fill="none">
      <Path
        d="M19.646 20.354a.5.5 0 10.708-.708l-16-16a.5.5 0 10-.708.708l16 16zM12 19.741c1.333 0 2.655-.246 3.9-.724a.5.5 0 00-.355-.934 9.913 9.913 0 01-3.545.658A10.012 10.012 0 012.527 12a10.073 10.073 0 012.62-4 .5.5 0 00-.683-.73 11.038 11.038 0 00-2.939 4.575.49.49 0 000 .312A11.009 11.009 0 0012 19.741zM8.1 4.979a.5.5 0 00.355.935A10.007 10.007 0 0121.472 12a10.03 10.03 0 01-2.611 4.013.5.5 0 00.686.729 11.012 11.012 0 002.912-4.542.5.5 0 00.016-.354A11.007 11.007 0 008.1 4.979z"
        fill={color}
      />
      <Path
        d="M16.09 13.9a.502.502 0 00.634-.315 4.967 4.967 0 00-6.3-6.3.5.5 0 10.318.948 3.965 3.965 0 015.036 5.037.5.5 0 00.312.63zM7.9 10.092a.5.5 0 00-.634.312 5 5 0 006.334 6.332.5.5 0 00-.323-.947 4 4 0 01-5.062-5.062.5.5 0 00-.315-.635z"
        fill={color}
      />
    </Svg>
  );
}
