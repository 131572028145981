import {Platform} from "react-native";
import {getTokenForCurrentUser} from "./authentication";
import {CarbonHeaders} from "./utils/constants";

export function createFetcher(baseUrl: string) {
  return async function fetcher(input: RequestInfo, init: RequestInit = {}) {
    const token = await getTokenForCurrentUser();
    let request: Request;
    if (typeof input === "string") {
      request = new Request(`${baseUrl}${input}`, init);
    } else {
      request = input;
    }

    request.headers.set("content-type", "application/json");
    if (token) {
      request.headers.set(CarbonHeaders.Authorization, `Bearer ${token}`);
    }
    request.headers.set(CarbonHeaders.CarbonPlatformType, Platform.OS);
    request.headers.set(CarbonHeaders.CarbonAppType, "business");
    request.headers.set(CarbonHeaders.CarbonAppVersion, __NEXT_DATA__.buildId);

    return await fetch(request);
  };
}
