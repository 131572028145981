import * as React from "react";
import Svg, {Path} from "react-native-svg";
import {IconProps, DISABLED_COLOR} from ".";

import colors from "../colors.json";

export default function EyeOpenIcon(props: IconProps) {
  const color = (props.disabled ? DISABLED_COLOR : props.color) || colors.gray[200];
  return (
    <Svg width={props.size} height={props.size} viewBox="0 0 24 24" fill="none">
      <Path
        d="M1.525 12.156a11.025 11.025 0 0020.949 0 .738.738 0 000-.311 11.026 11.026 0 00-20.95 0 .7.7 0 00.001.311zM12 5.259A10.005 10.005 0 0121.466 12a10.017 10.017 0 01-18.933 0A10.005 10.005 0 0112 5.259z"
        fill={color}
      />
      <Path
        d="M12 17a5 5 0 10-5-5 5.006 5.006 0 005 5zm0-9a4 4 0 110 8 4 4 0 010-8z"
        fill={color}
      />
      <Path
        d="M11.994 14.5a2.5 2.5 0 10-.394-4.968.501.501 0 00.16.988 1.5 1.5 0 11-1.187 1.014.5.5 0 10-.951-.309 2.5 2.5 0 002.372 3.275z"
        fill={color}
      />
    </Svg>
  );
}
