import * as React from "react";
import Svg, {Path} from "react-native-svg";
import {IconProps, DISABLED_COLOR} from ".";

import colors from "../colors.json";

export default function Group(props: IconProps) {
  const color = props.disabled ? DISABLED_COLOR : props.color;
  return (
    <Svg height={props.size} width={props.size} viewBox="0 0 20 20" fill="none">
      <Path
        d="M9.99999 19.5834C15.2927 19.5834 19.5833 15.2928 19.5833 10.0001C19.5833 4.70735 15.2927 0.416748 9.99999 0.416748C4.70726 0.416748 0.416656 4.70735 0.416656 10.0001C0.416656 15.2928 4.70726 19.5834 9.99999 19.5834Z"
        stroke={color || colors.gray[800]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M8.29739 8.29742C9.48409 8.29742 10.4461 7.33541 10.4461 6.14871C10.4461 4.96201 9.48409 4 8.29739 4C7.11069 4 6.14868 4.96201 6.14868 6.14871C6.14868 7.33541 7.11069 8.29742 8.29739 8.29742Z"
        stroke={color || colors.gray[800]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M12.5948 15.4599H4V14.5049C4 13.3652 4.45276 12.2721 5.25868 11.4662C6.06461 10.6603 7.15767 10.2075 8.29742 10.2075C9.43716 10.2075 10.5302 10.6603 11.3362 11.4662C12.1421 12.2721 12.5948 13.3652 12.5948 14.5049V15.4599Z"
        stroke={color || colors.gray[800]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M12.1175 4C12.6874 4 13.2339 4.22638 13.6369 4.62934C14.0398 5.0323 14.2662 5.57884 14.2662 6.14871C14.2662 6.71858 14.0398 7.26511 13.6369 7.66808C13.2339 8.07104 12.6874 8.29742 12.1175 8.29742"
        stroke={color || colors.gray[800]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M13.6451 10.3889C14.4585 10.6984 15.1589 11.2476 15.6534 11.9638C16.1479 12.68 16.4133 13.5295 16.4145 14.3998V15.4599H14.982"
        stroke={color || colors.gray[800]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
