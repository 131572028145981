import * as React from "react";
import Svg, {Path} from "react-native-svg";
import {IconProps, DISABLED_COLOR} from ".";

import colors from "../colors.json";

export default function No(props: IconProps) {
  const color = props.disabled ? DISABLED_COLOR : props.color;
  return (
    <Svg height={props.size} width={props.size} viewBox="0 0 22 22" fill="none">
      <Path
        d="M11 1.00006C9.02219 1.00006 7.08879 1.58655 5.4443 2.68536C3.79981 3.78418 2.51809 5.34597 1.76121 7.17323C1.00433 9.00049 0.806299 11.0112 1.19215 12.951C1.578 14.8908 2.53041 16.6726 3.92894 18.0711C5.32746 19.4697 7.10929 20.4221 9.0491 20.8079C10.9889 21.1938 12.9996 20.9957 14.8268 20.2389C16.6541 19.482 18.2159 18.2003 19.3147 16.5558C20.4135 14.9113 21 12.9779 21 11.0001C20.9971 8.34879 19.9426 5.80694 18.0679 3.93221C16.1931 2.05748 13.6513 1.00297 11 1.00006ZM11 20.0001C9.21997 20.0001 7.47991 19.4722 5.99987 18.4833C4.51983 17.4944 3.36628 16.0887 2.68509 14.4442C2.0039 12.7997 1.82567 10.9901 2.17294 9.24425C2.5202 7.49842 3.37737 5.89477 4.63604 4.6361C5.89472 3.37743 7.49836 2.52026 9.24419 2.17299C10.99 1.82573 12.7996 2.00396 14.4442 2.68515C16.0887 3.36633 17.4943 4.51989 18.4832 5.99993C19.4722 7.47997 20 9.22003 20 11.0001C19.9974 13.3862 19.0483 15.6738 17.361 17.3611C15.6738 19.0484 13.3861 19.9974 11 20.0001Z"
        fill={color || colors.gray[800]}
      />
      <Path
        d="M7 7.00101L14.9992 15.0002"
        stroke={color || colors.gray[800]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M7 14.9993L14.9992 7.00008"
        stroke={color || colors.gray[800]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
