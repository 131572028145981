import React from "react";

import AdobeFile from "./AdobeFile";
import Carbon from "./Carbon";
import Check from "./Check";
import ChevronDown from "./ChevronDown";
import Clipboard from "./Clipboard";
import Close from "./Close";
import Dashboard from "./Dashboard";
import DiagonalArrow from "./DiagonalArrow";
import Document from "./Document";
import EnrolledMember from "./EnrolledMember";
import Email from "./Email";
import EyeClosed from "./EyeClosed";
import EyeOpen from "./EyeOpen";
import Failure from "./Failure";
import Group from "./Group";
import IdCard from "./IdCard";
import Image from "./Image";
import Info from "./Info";
import Labs from "./Labs";
import LeftArrow from "./LeftArrow";
import Members from "./Members";
import No from "./No";
import Organization from "./Organization";
import People from "./People";
import Person from "./Person";
import Phone from "./Phone";
import Pin from "./Pin";
import Report from "./Report";
import Search from "./Search";
import Settings from "./Settings";
import Shot from "./Shot";
import SignOut from "./SignOut";
import Success from "./Success";
import Triangle from "./Triangle";
import Verified from "./Verified";
import colors from "../colors.json";
import {Color} from "react-native-svg";

export const DISABLED_COLOR = colors.gray[200];

export type IconName =
  | "adobe-file"
  | "carbon"
  | "check"
  | "chevron-down"
  | "clipboard"
  | "close"
  | "dashboard"
  | "diagonal-arrow"
  | "document"
  | "enrolled-member"
  | "email"
  | "eye-closed"
  | "eye-open"
  | "failure"
  | "group"
  | "id-card"
  | "image"
  | "info"
  | "labs"
  | "left-arrow"
  | "members"
  | "no"
  | "organization"
  | "people"
  | "person"
  | "phone"
  | "pin"
  | "report"
  | "search"
  | "settings"
  | "shot"
  | "sign-out"
  | "success"
  | "triangle"
  | "verified";

export const icons = {
  // Bounded components - icons that may have a built in background such as a
  // circle behind them
  carbon: Carbon,
  clipboard: Clipboard,
  person: Person,
  pin: Pin,
  report: Report,
  settings: Settings,
  // Unbounded components - icons without a built in background
  "adobe-file": AdobeFile,
  check: Check,
  "chevron-down": ChevronDown,
  close: Close,
  dashboard: Dashboard,
  "diagonal-arrow": DiagonalArrow,
  document: Document,
  email: Email,
  "enrolled-member": EnrolledMember,
  "eye-closed": EyeClosed,
  "eye-open": EyeOpen,
  failure: Failure,
  group: Group,
  "id-card": IdCard,
  image: Image,
  info: Info,
  labs: Labs,
  "left-arrow": LeftArrow,
  members: Members,
  no: No,
  organization: Organization,
  people: People,
  phone: Phone,
  search: Search,
  shot: Shot,
  "sign-out": SignOut,
  success: Success,
  triangle: Triangle,
  verified: Verified,
};

export type IconProps = {
  size: number;
  // disabled will take precedence over color by setting a default color
  // for disabled icons
  disabled?: boolean;
  color?: Color;
  clearBackground?: boolean;
};

export default function Icon({
  name,
  size,
  disabled,
  color,
  clearBackground,
}: IconProps & {name: IconName}) {
  // leaving this .toLowerCase although type checking should prevent a misuse
  // of capitals in the icon name, just to make sure nothing weird ever happens
  const IconComponent = icons[name.toLowerCase() as IconName];
  if (!IconComponent) {
    if (process.env.NODE_ENV !== "production")
      console.error(
        `Icon with name \`${name}\` not found. Check the icon name ` +
          `and make sure it is imported in \`icons/index.tsx\` and added to ` +
          `the \`icons\` object in the file`,
      );
    return null;
  }
  return (
    <IconComponent
      size={size}
      disabled={disabled}
      color={color}
      clearBackground={clearBackground}
    />
  );
}
