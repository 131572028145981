import React from "react";
import {View} from "react-native";
import {Subtitle, Paragraph} from ".";
import {tailwind} from "../styles";

const FallbackPageUI = () => (
  <View style={tailwind("w-full h-full flex-col justify-center items-center")}>
    <View style={tailwind("w-2/3 h-1/2 bg-gray-50")}></View>
    <Subtitle weight="bold" style={tailwind("mt-8")}>
      Oops!
    </Subtitle>
    <Paragraph style={tailwind("mt-2")}>An error occurred when loading this page.</Paragraph>
    <Paragraph>Try reloading the page or signing in again.</Paragraph>
    <Paragraph>If this problem persists, please contact support.</Paragraph>
  </View>
);

export default FallbackPageUI;
