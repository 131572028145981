import React, {FC} from "react";
import {Platform} from "react-native";
export type HeadProps = {
  children: React.ReactNode;
};

let NextHead: FC<HeadProps>;
if (Platform.OS === "web") {
  // eslint-disable-next-line
  NextHead = require("next/head").default;
}

export function Head(props: HeadProps) {
  if (Platform.OS === "web") {
    return <NextHead>{props.children}</NextHead>;
  }
  // the <head> doesn't exist in the native world
  return null;
}
