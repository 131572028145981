import * as React from "react";
import Svg, {Path} from "react-native-svg";
import {IconProps, DISABLED_COLOR} from ".";

import colors from "../colors.json";

export default function ClipboardIcon(props: IconProps) {
  const color = props.disabled ? DISABLED_COLOR : props.color;
  return (
    <Svg width={props.size} height={props.size} viewBox="0 0 24 24" fill="none">
      <Path
        d="M18.5 20.5C18.5 20.7652 18.3946 21.0196 18.2071 21.2071C18.0196 21.3946 17.7652 21.5 17.5 21.5H4.5C4.23478 21.5 3.98043 21.3946 3.79289 21.2071C3.60536 21.0196 3.5 20.7652 3.5 20.5V5.5C3.5 5.23478 3.60536 4.98043 3.79289 4.79289C3.98043 4.60536 4.23478 4.5 4.5 4.5H5.5"
        stroke={color || colors.gray[800]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M16.5 4.5H17.5C17.7652 4.5 18.0196 4.60536 18.2071 4.79289C18.3946 4.98043 18.5 5.23478 18.5 5.5V6.538"
        stroke={color || colors.gray[800]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M6.5 10.5H10"
        stroke={color || colors.gray[800]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M6.5 13.5H10.1"
        stroke={color || colors.gray[800]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M6.5 16.5H12"
        stroke={color || colors.gray[800]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M15 5.5C15 4.96957 14.7893 4.46086 14.4142 4.08579C14.0391 3.71071 13.5304 3.5 13 3.5C13 2.96957 12.7893 2.46086 12.4142 2.08579C12.0391 1.71071 11.5304 1.5 11 1.5C10.4696 1.5 9.96086 1.71071 9.58579 2.08579C9.21071 2.46086 9 2.96957 9 3.5C8.46957 3.5 7.96086 3.71071 7.58579 4.08579C7.21071 4.46086 7 4.96957 7 5.5V6.5H15V5.5Z"
        stroke={color || colors.gray[800]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M18.5 18C20.9853 18 23 15.9853 23 13.5C23 11.0147 20.9853 9 18.5 9C16.0147 9 14 11.0147 14 13.5C14 15.9853 16.0147 18 18.5 18Z"
        stroke={color || colors.gray[800]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M17.071 13.3573L18.1111 14.5L19.931 12.5"
        stroke={color || colors.gray[800]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
