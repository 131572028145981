import {useAsyncStorage} from "@react-native-async-storage/async-storage";
import {createContext, useContext, useEffect, useState, ReactNode} from "react";
import {merge} from "lodash";
import {Maybe} from "../types";
import {userSettings} from "../utils/constants";

export enum NavState {
  Expanded = "Expanded",
  Collapsed = "Collapsed",
}

export type UserSettings = {
  navState?: NavState;
};
export type UserSettingKeys = keyof UserSettings;

type UserSettingsProvider = {
  children: ReactNode;
};

export type UserSettingsContext = {
  loading: boolean;
  settings: Maybe<UserSettings>;
  writeSetting(key: UserSettingKeys, value: unknown): Promise<void>;
};

export const UserSettings = createContext(Object.create(null) as UserSettingsContext);

export const useSettings = () => useContext(UserSettings);

export function UserSettingsProvider({children}: UserSettingsProvider) {
  const [settings, setSettings] = useState<Maybe<UserSettings> | undefined>(undefined);
  const {getItem, setItem} = useAsyncStorage(userSettings);
  const loading = settings === undefined;

  useEffect(() => {
    if (settings !== undefined) return;
    getItem().then(settings => {
      setSettings(settings ? (JSON.parse(settings) as UserSettings) : null);
    });
  }, [settings, getItem]);

  function writeSetting(key: UserSettingKeys, value: unknown) {
    const updatedSettings = merge(Object.create(null), settings, {[key]: value});
    setSettings(updatedSettings);
    return setItem(JSON.stringify(updatedSettings));
  }

  return (
    <UserSettings.Provider value={{settings: !loading ? settings : null, loading, writeSetting}}>
      {children}
    </UserSettings.Provider>
  );
}
