import {UUID} from "@ch/foundations/types";
import {useRouter} from "./useRouter";
import {useEffect, useState, createContext, ReactNode, useContext} from "react";
import {
  clearCurrentUserToken,
  getTokenForCurrentUser,
  setTokens,
  getTrustedDeviceCode,
  setTrustedDeviceCode,
} from "../authentication";
import {fetch as monolith} from "../../monolith";
import {CustomHttpStatus} from "../utils/constants";
import {assign} from "lodash";

export type SignInCredentials = {
  email: string;
  password: string;
  twoFactorCode?: string;
  rememberDevice?: boolean;
  trustedDeviceCode?: string;
};

type AuthContextData = {
  user: User | null;
  loading: boolean;
  typ: AuthStyle;
  signIn: (credentials: SignInCredentials) => Promise<void>;
  signOut: () => Promise<void>;
  forgotPassword: (email: string) => Promise<void>;
  resetPassword: (password: string, code: string) => Promise<void>;
};

type AuthProviderProps = {
  children: ReactNode;
  onSignOut?: () => Promise<unknown>;
};

type User = {
  id: UUID;
  trustedDeviceCode?: string | null;
};

type SignInResponse = {
  userId: UUID;
  trustedDeviceCode: string | null;
};

type ResetResponse = {
  email: string;
};

export enum AuthStyle {
  Basic,
  TwoFactorAuth,
}

export const RESET_FAILURE_MESSAGE = "Unable to reset your password, please try again";

export const AuthContext = createContext(Object.create(null) as AuthContextData);

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({children, onSignOut}: AuthProviderProps) {
  const router = useRouter();
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [typ, setType] = useState<AuthStyle>(AuthStyle.Basic);
  const [resetPasswordCreds, setResetPasswordCreds] = useState<SignInCredentials | null>(null);

  useEffect(() => {
    let mounted = true;
    getTokenForCurrentUser()
      .then(token => {
        if (!token) return;
        return monolith("/users/me/id").then(async result => {
          if (result.status < 400) {
            const id = await result.json();
            if (id && mounted) setUser({id});
          } else {
            clearCurrentUserToken().then(() => setUser(null));
          }
        });
      })
      .finally(() => mounted && setLoading(false));
    return () => {
      mounted = false;
    };
  }, []);

  async function signIn(body: SignInCredentials) {
    const trustedDeviceCode = await getTrustedDeviceCode(body.email);

    const signInBody: SignInCredentials = {...body, trustedDeviceCode};

    // if we have email + password from a reset flow, add them here
    if (resetPasswordCreds) assign(signInBody, resetPasswordCreds);

    // TODO(@jbaxleyiii) match validation from HIB
    const response = await monolith("/login", {
      method: "POST",
      body: JSON.stringify(signInBody),
    });

    // if the user needs to enter a code from a text, then we update
    // the route of current page so the UI can respond and pass in
    // the twoFactor auth code
    if (response.status === CustomHttpStatus.NEED_TWO_FACTOR_AUTH) {
      const lastFour = await response.text();
      setType(AuthStyle.TwoFactorAuth);
      // we want to ensure that we go to /sign-in in case this sign-in path
      // is hit from another page
      await router.push({pathname: "/sign-in", query: {lastFour}});
      return;
    }

    if (response.status < 400) {
      const token = response.headers.get("x-auth-token");
      const user = (await response.json()) as SignInResponse;
      if (token && user.userId) {
        await setTokens(user.userId, token);
        if (user.trustedDeviceCode) {
          await setTrustedDeviceCode(body.email, user.trustedDeviceCode);
        }
        setUser({id: user.userId});
        const redirectPath = router.query.rdp || "/";
        await router.replace(redirectPath as string);
        setResetPasswordCreds(null);
        setType(AuthStyle.Basic); // reset to basic auth for later attempts
      }
    } else {
      throw new Error(response.statusText);
    }
  }

  async function signOut() {
    const signOutCb = onSignOut ? onSignOut() : Promise.resolve();
    await Promise.all([
      router.push("/sign-in"),
      clearCurrentUserToken(),
      signOutCb.then(() => setUser(null)),
      monolith("/logout", {method: "POST", body: JSON.stringify({})}),
    ]);
  }

  function forgotPassword(email: string) {
    // forgot-password
    return monolith("/forgot-password", {
      method: "POST",
      // useBranch = false lets us direct to this new dashboard
      body: JSON.stringify({email, useBranch: false}),
    }).then(response => {
      if (response.status >= 400) throw new Error(response.statusText);
      return;
    });
  }

  function resetPassword(password: string, code: string) {
    return monolith("/set-password", {
      method: "POST",
      body: JSON.stringify({password, code}),
    })
      .then(async response => {
        if (response.status >= 400) {
          let message;
          try {
            const payload = await response.json();
            if ("message" in payload) message = payload.message;
          } catch (_) {}

          if (!!message) {
            throw new Error(message);
          } else {
            throw new Error(RESET_FAILURE_MESSAGE);
          }
        }
        return response.json();
      })
      .then(({email}: ResetResponse) => {
        // store the credentials for the *next sign in only* so we can
        // handle 2FA after a reset flow
        setResetPasswordCreds({email, password});
        return signIn({email, password});
      });
  }

  return (
    <AuthContext.Provider
      value={{user, signIn, signOut, loading, forgotPassword, resetPassword, typ}}
    >
      {!loading && children}
    </AuthContext.Provider>
  );
}
