import * as React from "react";
import Svg, {Path} from "react-native-svg";
import {IconProps, DISABLED_COLOR} from ".";
import colors from "../colors.json";

export default function ReportIcon(props: IconProps) {
  const color = props.disabled ? DISABLED_COLOR : props.color;
  return (
    <Svg width={props.size} height={props.size} viewBox="0 0 24 24" fill="none">
      <Path
        d="M7.5 9.5L9.5 11.5L12.5 7.5"
        stroke={color || colors.gray[800]}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M13.5 10.5H16.5"
        stroke={color || colors.gray[800]}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M16.5 16.5H13.5"
        stroke={color || colors.gray[800]}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M7.5 14.5L11.5 18.5"
        stroke={color || colors.gray[800]}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M7.5 18.5L11.5 14.5"
        stroke={color || colors.gray[800]}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M19.5 21.5C19.5 22.1 19.1 22.5 18.5 22.5H5.5C4.9 22.5 4.5 22.1 4.5 21.5V4.5C4.5 3.9 4.9 3.5 5.5 3.5C6.2 3.5 8.5 3.5 8.5 3.5"
        stroke={color || colors.gray[800]}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M15.5 3.5C15.5 3.5 18.6 3.5 18.5 3.5C19.1 3.5 19.5 3.9 19.5 4.5V21.5"
        stroke={color || colors.gray[800]}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M10.5999 0.5H13.2999C13.8999 0.5 14.3999 1 14.3999 1.6V2.5H15.3999V4.5H8.3999V2.5H9.3999V1.6C9.4999 1 9.9999 0.5 10.5999 0.5Z"
        fill={color || colors.gray[800]}
        stroke={color || colors.gray[800]}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
