import * as React from "react";
import Svg, {Path, Circle} from "react-native-svg";
import {IconProps, DISABLED_COLOR} from ".";

import colors from "../colors.json";

export default function PersonIcon(props: IconProps) {
  const color = props.disabled ? DISABLED_COLOR : props.color;
  if (props.clearBackground)
    return (
      <Svg width={props.size} height={props.size} viewBox="0 0 17 18" fill="none">
        <Path
          d="M13.3305 5.83599C13.3305 2.62999 11.4485 0.635986 8.42046 0.635986C5.39246 0.635986 3.50946 2.62999 3.50946 5.83599C3.50946 9.11399 5.52646 12.767 8.42046 12.767C11.3145 12.767 13.3305 9.11399 13.3305 5.83599ZM8.42046 11.767C6.37346 11.767 4.50946 8.93899 4.50946 5.83599C4.50946 3.16899 5.93446 1.63599 8.42046 1.63599C10.9065 1.63599 12.3305 3.16599 12.3305 5.83599C12.3305 8.93899 10.4665 11.767 8.42046 11.767Z"
          fill={color || colors.blue.darker}
        />
        <Path
          d="M5.71044 12.483H5.69644C3.83044 12.533 0.237443 13.452 0.00144305 16.572C-0.00863517 16.7046 0.0343777 16.8357 0.12102 16.9366C0.207661 17.0375 0.330835 17.0999 0.463443 17.11C0.596051 17.12 0.727232 17.077 0.828127 16.9904C0.929021 16.9037 0.991365 16.7806 1.00144 16.648C1.22644 13.648 5.68044 13.484 5.72544 13.483C5.85805 13.4812 5.98454 13.4269 6.07709 13.3319C6.16964 13.2369 6.22067 13.1091 6.21894 12.9765C6.21722 12.8438 6.16289 12.7174 6.0679 12.6248C5.97291 12.5323 5.84505 12.4812 5.71244 12.483H5.71044Z"
          fill={color || colors.blue.darker}
        />
        <Path
          d="M11.1444 12.483H11.1314C10.9988 12.4811 10.8709 12.532 10.7758 12.6245C10.6808 12.7169 10.6263 12.8434 10.6244 12.976C10.6226 13.1086 10.6735 13.2365 10.7659 13.3316C10.8584 13.4266 10.9848 13.4811 11.1174 13.483C11.1624 13.483 15.6174 13.638 15.8404 16.648C15.8505 16.7806 15.9129 16.9037 16.0138 16.9904C16.1146 17.077 16.2458 17.12 16.3784 17.11C16.511 17.0999 16.6342 17.0375 16.7209 16.9366C16.8075 16.8357 16.8505 16.7046 16.8404 16.572C16.6024 13.452 13.0104 12.533 11.1444 12.483Z"
          fill={color || colors.blue.darker}
        />
      </Svg>
    );

  return (
    <Svg width={props.size} height={props.size} viewBox="0 0 64 64" fill="none">
      <Circle
        opacity="0.4"
        cx="32"
        cy="32"
        r="32"
        fill={props.clearBackground ? "transparent" : color || colors.blue.light}
      />
      <Path
        d="M41.6609 27.6715C41.6609 21.2595 37.8969 17.2715 31.8409 17.2715C25.7849 17.2715 22.0189 21.2595 22.0189 27.6715C22.0189 34.2275 26.0529 41.5335 31.8409 41.5335C37.6289 41.5335 41.6609 34.2275 41.6609 27.6715ZM31.8409 39.5335C27.7469 39.5335 24.0189 33.8775 24.0189 27.6715C24.0189 22.3375 26.8689 19.2715 31.8409 19.2715C36.8129 19.2715 39.6609 22.3315 39.6609 27.6715C39.6609 33.8775 35.9329 39.5335 31.8409 39.5335Z"
        fill={color || colors.blue.darker}
      />
      <Path
        d="M26.4209 40.9659H26.3929C22.6609 41.0659 15.4749 42.9039 15.0029 49.1439C14.9827 49.4091 15.0688 49.6715 15.242 49.8733C15.4153 50.0751 15.6617 50.1998 15.9269 50.2199C16.1921 50.2401 16.4545 50.154 16.6563 49.9808C16.858 49.8075 16.9827 49.5611 17.0029 49.2959C17.4529 43.2959 26.3609 42.9679 26.4509 42.9659C26.7161 42.9625 26.9691 42.8538 27.1542 42.6638C27.3393 42.4738 27.4413 42.2181 27.4379 41.9529C27.4344 41.6877 27.3258 41.4347 27.1358 41.2496C26.9458 41.0645 26.6901 40.9625 26.4249 40.9659H26.4209Z"
        fill={color || colors.blue.darker}
      />
      <Path
        d="M37.2889 40.9659H37.2629C36.9977 40.9622 36.7418 41.064 36.5517 41.2489C36.3615 41.4338 36.2526 41.6867 36.2489 41.9519C36.2452 42.2171 36.347 42.473 36.5319 42.6631C36.7168 42.8533 36.9697 42.9622 37.2349 42.9659C37.3249 42.9659 46.2349 43.2759 46.6809 49.2959C46.701 49.5611 46.8257 49.8075 47.0275 49.9808C47.2293 50.1541 47.4917 50.2401 47.7569 50.2199C48.0221 50.1998 48.2684 50.0751 48.4417 49.8733C48.615 49.6715 48.701 49.4091 48.6809 49.1439C48.2049 42.9039 41.0209 41.0659 37.2889 40.9659Z"
        fill={color || colors.blue.darker}
      />
    </Svg>
  );
}
