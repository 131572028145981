import * as React from "react";
import Svg, {Path, Rect} from "react-native-svg";
import {IconProps, DISABLED_COLOR} from ".";

import colors from "../colors.json";

export default function Email(props: IconProps) {
  const color = props.disabled ? DISABLED_COLOR : props.color;
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <Rect x="2.5" y="4.5" width="19" height="14" rx="1.5" stroke={color || colors.gray[800]} />
      <Path
        d="M2.88086 5.49927L10.2308 12.1142C11.2072 12.9929 12.7901 12.9929 13.7664 12.1142L21.1163 5.49927"
        stroke={color || colors.gray[800]}
      />
    </Svg>
  );
}
