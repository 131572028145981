import * as React from "react";
import Svg, {Path, Rect} from "react-native-svg";
import {IconProps, DISABLED_COLOR} from ".";

import colors from "../colors.json";

export default function IdCard(props: IconProps) {
  const color = props.disabled ? DISABLED_COLOR : props.color;
  return (
    <Svg height={props.size} width={props.size} viewBox="0 0 20 19" fill="none">
      <Rect x="0.5" y="4.5" width="19" height="14" rx="1.5" stroke={color || colors.gray[800]} />
      <Path
        d="M6.44444 5H13.5556C13.6734 5 13.7865 4.95611 13.8698 4.878C13.9532 4.79988 14 4.69393 14 4.58345V3.75035C13.9992 3.26433 13.8174 2.79382 13.4861 2.41998C13.1547 2.04614 12.6945 1.79242 12.1849 1.70259C12.0902 1.22433 11.8198 0.792231 11.4207 0.481245C11.0216 0.170259 10.519 0 10 0C9.481 0 8.97836 0.170259 8.57928 0.481245C8.1802 0.792231 7.90985 1.22433 7.81511 1.70259C7.30548 1.79242 6.84529 2.04614 6.51392 2.41998C6.18255 2.79382 6.0008 3.26433 6 3.75035V4.58345C6 4.69393 6.04683 4.79988 6.13017 4.878C6.21352 4.95611 6.32657 5 6.44444 5ZM6.88889 3.75035C6.88889 3.41892 7.02936 3.10107 7.27941 2.86671C7.52946 2.63236 7.8686 2.5007 8.22222 2.5007C8.3401 2.5007 8.45314 2.45681 8.53649 2.37869C8.61984 2.30057 8.66667 2.19462 8.66667 2.08415C8.66667 1.75272 8.80714 1.43486 9.05719 1.20051C9.30724 0.966153 9.64638 0.834493 10 0.834493C10.3536 0.834493 10.6928 0.966153 10.9428 1.20051C11.1929 1.43486 11.3333 1.75272 11.3333 2.08415C11.3333 2.19462 11.3802 2.30057 11.4635 2.37869C11.5469 2.45681 11.6599 2.5007 11.7778 2.5007C12.1314 2.5007 12.4705 2.63236 12.7206 2.86671C12.9706 3.10107 13.1111 3.41892 13.1111 3.75035V4.1669H6.88889V3.75035Z"
        fill={color || colors.gray[800]}
      />
      <Path
        d="M3.47794 9H16.5221C16.6488 9 16.7704 8.94732 16.86 8.85355C16.9496 8.75979 17 8.63261 17 8.5C17 8.36739 16.9496 8.24021 16.86 8.14645C16.7704 8.05268 16.6488 8 16.5221 8H3.47794C3.35118 8 3.22962 8.05268 3.13999 8.14645C3.05035 8.24021 3 8.36739 3 8.5C3 8.63261 3.05035 8.75979 3.13999 8.85355C3.22962 8.94732 3.35118 9 3.47794 9Z"
        fill={color || colors.gray[800]}
      />
      <Path
        d="M3.47794 12H16.5221C16.6488 12 16.7704 11.9473 16.86 11.8536C16.9496 11.7598 17 11.6326 17 11.5C17 11.3674 16.9496 11.2402 16.86 11.1464C16.7704 11.0527 16.6488 11 16.5221 11H3.47794C3.35118 11 3.22962 11.0527 3.13999 11.1464C3.05035 11.2402 3 11.3674 3 11.5C3 11.6326 3.05035 11.7598 3.13999 11.8536C3.22962 11.9473 3.35118 12 3.47794 12Z"
        fill={color || colors.gray[800]}
      />
      <Path
        d="M3.5 15H8.1C8.23261 15 8.35979 14.9473 8.45355 14.8536C8.54732 14.7598 8.6 14.6326 8.6 14.5C8.6 14.3674 8.54732 14.2402 8.45355 14.1464C8.35979 14.0527 8.23261 14 8.1 14H3.5C3.36739 14 3.24021 14.0527 3.14645 14.1464C3.05268 14.2402 3 14.3674 3 14.5C3 14.6326 3.05268 14.7598 3.14645 14.8536C3.24021 14.9473 3.36739 15 3.5 15V15Z"
        fill={color || colors.gray[800]}
      />
    </Svg>
  );
}
