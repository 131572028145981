import * as React from "react";
import Svg, {Path} from "react-native-svg";
import {IconProps, DISABLED_COLOR} from ".";

import colors from "../colors.json";

export default function LeftArrow(props: IconProps) {
  const color = props.disabled ? DISABLED_COLOR : props.color;
  return (
    <Svg height={props.size} width={props.size} viewBox="0 0 10 10" fill="none">
      <Path
        d="M0.763978 4.29155C0.576507 4.4793 0.471191 4.73391 0.471191 4.99939C0.471191 5.26487 0.576507 5.51948 0.763978 5.70723L4.39998 9.34555C4.46406 9.40077 4.5467 9.42954 4.63117 9.42605C4.71564 9.42255 4.79563 9.38705 4.85495 9.32674C4.91426 9.26642 4.94847 9.18579 4.95064 9.10117C4.95281 9.01656 4.92279 8.93428 4.86664 8.87099L1.33331 5.33346H9.19531C9.28372 5.33346 9.3685 5.2983 9.43101 5.23571C9.49353 5.17312 9.52864 5.08824 9.52864 4.99973C9.52864 4.91122 9.49353 4.82633 9.43101 4.76374C9.3685 4.70116 9.28372 4.666 9.19531 4.666H1.33331L4.86664 1.12846C4.89848 1.09768 4.92387 1.06085 4.94134 1.02014C4.95881 0.979421 4.96801 0.935629 4.96839 0.891317C4.96878 0.847005 4.96035 0.803059 4.94359 0.762045C4.92683 0.721031 4.90207 0.683769 4.87078 0.652435C4.83948 0.6211 4.80226 0.596319 4.7613 0.579539C4.72033 0.562759 4.67644 0.554315 4.63218 0.5547C4.58792 0.555085 4.54418 0.564292 4.50351 0.581782C4.46284 0.599272 4.42606 0.624697 4.39531 0.656571L0.763978 4.29155Z"
        fill={color || colors.gray[800]}
      />
    </Svg>
  );
}
