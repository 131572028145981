import * as React from "react";
import Svg, {Path} from "react-native-svg";
import {IconProps, DISABLED_COLOR} from ".";

import colors from "../colors.json";

export default function DocumentIcon(props: IconProps) {
  const color = props.disabled ? DISABLED_COLOR : props.color;
  return (
    <Svg width={props.size} height={props.size} viewBox="0 0 20 10" fill="none">
      <Path
        d="M2 1.57129L10 8.42843L18 1.57129"
        stroke={color || colors.gray[400]}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
