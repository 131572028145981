import * as React from "react";
import Svg, {Path} from "react-native-svg";
import {DISABLED_COLOR, IconProps} from ".";

import colors from "../colors.json";

export default function Triangle(props: IconProps) {
  const color = props.disabled ? DISABLED_COLOR : props.color;
  return (
    <Svg height={props.size} width={props.size} viewBox="0 0 16 16" fill="none">
      <Path
        d="M6.88212 0.654545C7.37896 -0.218182 8.62104 -0.218181 9.11788 0.654546L15.8251 14.0364C16.322 14.9091 15.7009 16 14.7073 16H1.29274C0.29907 16 -0.321972 14.9091 0.174862 14.0364L6.88212 0.654545Z"
        fill={color || colors.gray[800]}
      />
    </Svg>
  );
}
