import React from "react";
import {StyleProp, Text} from "react-native";
import {StyleSheet, TextStyle} from "react-native";
import Colors from "./colors.json";

type ParagraphProps = {
  level?: 1 | 2;
  weight?: "light" | "bold" | "normal";
  numberOfLines?: number;
  style?: StyleProp<TextStyle>;
  children: React.ReactNode;
  accessibilityRole?: "heading" | "button" | "label" | "link" | "listitem";
  testID?: string;
};

export default function Paragraph({
  level = 1,
  weight = "normal",
  numberOfLines,
  style,
  children,
  accessibilityRole,
  testID,
}: ParagraphProps) {
  return (
    <Text
      accessibilityRole={accessibilityRole}
      numberOfLines={numberOfLines}
      style={[paragraphStyles[level], paragraphStyles[weight], style]}
      testID={testID}
    >
      {children}
    </Text>
  );
}

const paragraphStyles = StyleSheet.create({
  bold: {fontFamily: "Inter-semibold"},
  light: {fontFamily: "Inter-light"},
  normal: {fontFamily: "Inter-regular"},
  1: {
    fontSize: 16,
    lineHeight: 24,
    color: Colors.gray[800],
  },
  2: {
    fontSize: 14,
    lineHeight: 20,
    color: Colors.gray[800],
  },
});
