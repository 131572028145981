import * as React from "react";
import Svg, {Path} from "react-native-svg";
import {IconProps, DISABLED_COLOR} from ".";
import Colors from "../colors.json";

export default function Labs(props: IconProps) {
  const color = props.disabled ? DISABLED_COLOR : props.color;
  return (
    <Svg width={props.size} height={props.size} viewBox="0 0 24 25" fill="none">
      <Path
        d="M11 9.5C11.1326 9.5 11.2598 9.44732 11.3536 9.35355C11.4473 9.25979 11.5 9.13261 11.5 9V5C11.5 4.60218 11.342 4.22064 11.0607 3.93934C10.7794 3.65804 10.3978 3.5 10 3.5H6C5.60218 3.5 5.22064 3.65804 4.93934 3.93934C4.65804 4.22064 4.5 4.60218 4.5 5V9C4.5 9.13261 4.55268 9.25979 4.64645 9.35355C4.74021 9.44732 4.86739 9.5 5 9.5H11ZM5.5 5C5.5 4.86739 5.55268 4.74021 5.64645 4.64645C5.74021 4.55268 5.86739 4.5 6 4.5H10C10.1326 4.5 10.2598 4.55268 10.3536 4.64645C10.4473 4.74021 10.5 4.86739 10.5 5V8.5H5.5V5Z"
        fill={color || Colors.gray[800]}
      />
      <Path
        d="M5.5 15.5C5.36739 15.5 5.24021 15.5527 5.14645 15.6464C5.05268 15.7402 5 15.8674 5 16V20C5 20.7956 5.31607 21.5587 5.87868 22.1213C6.44129 22.6839 7.20435 23 8 23C8.79565 23 9.55871 22.6839 10.1213 22.1213C10.6839 21.5587 11 20.7956 11 20V16C11 15.8674 10.9473 15.7402 10.8536 15.6464C10.7598 15.5527 10.6326 15.5 10.5 15.5C10.3674 15.5 10.2402 15.5527 10.1464 15.6464C10.0527 15.7402 10 15.8674 10 16V20C10 20.5304 9.78929 21.0391 9.41421 21.4142C9.03914 21.7893 8.53043 22 8 22C7.46957 22 6.96086 21.7893 6.58579 21.4142C6.21071 21.0391 6 20.5304 6 20V16C6 15.8674 5.94732 15.7402 5.85355 15.6464C5.75979 15.5527 5.63261 15.5 5.5 15.5Z"
        fill={color || Colors.gray[800]}
      />
      <Path
        d="M21 14.5C21.1326 14.5 21.2598 14.4473 21.3536 14.3536C21.4473 14.2598 21.5 14.1326 21.5 14C21.5 13.8674 21.4473 13.7402 21.3536 13.6464C21.2598 13.5527 21.1326 13.5 21 13.5H19V11C19 10.8674 18.9473 10.7402 18.8536 10.6464C18.7598 10.5527 18.6326 10.5 18.5 10.5C18.3674 10.5 18.2402 10.5527 18.1464 10.6464C18.0527 10.7402 18 10.8674 18 11V13.5H14V11C14 10.8674 13.9473 10.7402 13.8536 10.6464C13.7598 10.5527 13.6326 10.5 13.5 10.5C13.3674 10.5 13.2402 10.5527 13.1464 10.6464C13.0527 10.7402 13 10.8674 13 11V13.5H11V11C11 10.8674 10.9473 10.7402 10.8536 10.6464C10.7598 10.5527 10.6326 10.5 10.5 10.5C10.3674 10.5 10.2402 10.5527 10.1464 10.6464C10.0527 10.7402 10 10.8674 10 11V13.5H6V11C6 10.8674 5.94732 10.7402 5.85355 10.6464C5.75979 10.5527 5.63261 10.5 5.5 10.5C5.36739 10.5 5.24021 10.5527 5.14645 10.6464C5.05268 10.7402 5 10.8674 5 11V13.5H3C2.86739 13.5 2.74021 13.5527 2.64645 13.6464C2.55268 13.7402 2.5 13.8674 2.5 14C2.5 14.1326 2.55268 14.2598 2.64645 14.3536C2.74021 14.4473 2.86739 14.5 3 14.5H21Z"
        fill={color || Colors.gray[800]}
      />
      <Path
        d="M14 3.5C13.6022 3.5 13.2206 3.65804 12.9393 3.93934C12.658 4.22064 12.5 4.60218 12.5 5V9C12.5 9.13261 12.5527 9.25979 12.6464 9.35355C12.7402 9.44732 12.8674 9.5 13 9.5H19C19.1326 9.5 19.2598 9.44732 19.3536 9.35355C19.4473 9.25979 19.5 9.13261 19.5 9V5C19.5 4.60218 19.342 4.22064 19.0607 3.93934C18.7794 3.65804 18.3978 3.5 18 3.5H14ZM18.5 5V8.5H13.5V5C13.5 4.86739 13.5527 4.74021 13.6464 4.64645C13.7402 4.55268 13.8674 4.5 14 4.5H18C18.1326 4.5 18.2598 4.55268 18.3536 4.64645C18.4473 4.74021 18.5 4.86739 18.5 5Z"
        fill={color || Colors.gray[800]}
      />
      <Path
        d="M13.5 15.5C13.3674 15.5 13.2402 15.5527 13.1464 15.6464C13.0527 15.7402 13 15.8674 13 16V20C13 20.7956 13.3161 21.5587 13.8787 22.1213C14.4413 22.6839 15.2044 23 16 23C16.7956 23 17.5587 22.6839 18.1213 22.1213C18.6839 21.5587 19 20.7956 19 20V16C19 15.8674 18.9473 15.7402 18.8536 15.6464C18.7598 15.5527 18.6326 15.5 18.5 15.5C18.3674 15.5 18.2402 15.5527 18.1464 15.6464C18.0527 15.7402 18 15.8674 18 16V20C18 20.5304 17.7893 21.0391 17.4142 21.4142C17.0391 21.7893 16.5304 22 16 22C15.4696 22 14.9609 21.7893 14.5858 21.4142C14.2107 21.0391 14 20.5304 14 20V16C14 15.8674 13.9473 15.7402 13.8536 15.6464C13.7598 15.5527 13.6326 15.5 13.5 15.5Z"
        fill={color || Colors.gray[800]}
      />
    </Svg>
  );
}
