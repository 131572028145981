import AsyncStorage from "@react-native-async-storage/async-storage";
import {omit} from "lodash";

import {UUID} from "./types";
import {
  apiTokenV2StorageKey,
  currentUserIdStorageKey,
  trustedDeviceCodesKey,
} from "./utils/constants";

export type Tokens = {
  [userId: string /* UUID */]: string | null;
};

let sessionTokens: Tokens = Object.create(null);
let currentUserId: UUID | null | undefined = null;

export const saveCurrentUserId = (userId: UUID) =>
  AsyncStorage.setItem(currentUserIdStorageKey, userId).tee(getCurrentUserId);

export const getCurrentUserId: () => Promise<string | null> = () =>
  AsyncStorage.getItem(currentUserIdStorageKey).then(userId => {
    currentUserId = userId;
    return userId;
  });

const removeCurrentUserId = () =>
  AsyncStorage.removeItem(currentUserIdStorageKey).tee(getCurrentUserId);

export const clearCurrentUserToken = () => {
  return getCurrentUserId().then(currentUserId => {
    Promise.all([removeToken(currentUserId), removeCurrentUserId()]);
  });
};

export const getTokens = () => {
  return AsyncStorage.getItem(apiTokenV2StorageKey)
    .then(tokens => (tokens ? (JSON.parse(tokens) as Tokens) : (Object.create(null) as Tokens)))
    .then(tokens => {
      sessionTokens = tokens;
      return tokens;
    })
    .catch(() => Object.create(null) as Tokens);
};

export const setTokens = (userId: UUID, token: string) =>
  getTokens()
    .then(tokens =>
      AsyncStorage.setItem(apiTokenV2StorageKey, JSON.stringify({...tokens, [userId]: token})),
    )
    .tee(getTokens)
    .tee(() => saveCurrentUserId(userId));

const removeToken = (userId: UUID | null) =>
  getTokens()
    .then(tokens => {
      if (!userId) return;
      AsyncStorage.setItem(apiTokenV2StorageKey, JSON.stringify(omit(tokens, userId)));
    })
    .tee(getTokens);

export const getTokenForCurrentUser = () => {
  if (currentUserId && sessionTokens[currentUserId])
    return Promise.resolve(sessionTokens[currentUserId]);

  return Promise.all([getCurrentUserId(), getTokens()]).then(([userId, tokens]) => {
    if (!userId) return null;
    return tokens?.[userId];
  });
};

export const getTrustedDeviceCode = (email: string) =>
  AsyncStorage.getItem(trustedDeviceCodesKey).then(
    codeLookup => codeLookup && JSON.parse(codeLookup)[email],
  );

export const setTrustedDeviceCode = (email: string, code: string) =>
  AsyncStorage.getItem(trustedDeviceCodesKey)
    .then(codeLookup => (codeLookup ? JSON.parse(codeLookup) : {}))
    .then(codeLookup =>
      AsyncStorage.setItem(trustedDeviceCodesKey, JSON.stringify({...codeLookup, [email]: code})),
    );
