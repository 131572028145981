import * as React from "react";
import {Head} from "../lib/Head";
import {AppProps} from "next/app";
import {ApolloProvider} from "@apollo/client";
import {client} from "../lib/graphql";

import "../styles/global.css";
import "@ch/foundations/utils/promiseExtensions";
import {AuthProvider} from "@ch/foundations/hooks/useAuth";
import {FallbackPageUI} from "@ch/foundations/ui";
import {ErrorBoundary} from "@sentry/nextjs";
import {UserSettingsProvider} from "@ch/foundations/hooks/useSettings";
import {FeatureFlagProvider} from "@ch/foundations/hooks/useFeatureFlag";
import {ToastProvider} from "react-native-toast-notifications";
import toastOptions from "../lib/foundations/toastOptions";

export default function BusinessDashboard({Component, pageProps}: AppProps) {
  /**
   *
   * Currently the carbon stack uses header and local storage based authentication.
   * Because of this, it is not possible for the server to know if the users is logged in
   * (and then fetch the data for the page), because it relies on local storage. If we
   * want full server side rendering for the application, we would need to start to use
   * cookies for session data. Instead, we generate a shell application to load while the client
   * boots and checks headers. This is why it is important for each page to be as fast as possible
   * so that we can boot quicker
   *
   */
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <ErrorBoundary fallback={FallbackPageUI}>
        <ToastProvider {...toastOptions}>
          <UserSettingsProvider>
            <AuthProvider onSignOut={() => client.clearStore()}>
              <ApolloProvider client={client}>
                <FeatureFlagProvider client={client}>
                  <Component {...pageProps} />
                </FeatureFlagProvider>
              </ApolloProvider>
            </AuthProvider>
          </UserSettingsProvider>
        </ToastProvider>
      </ErrorBoundary>
    </>
  );
}
