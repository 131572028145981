import * as React from "react";
import Svg, {Path} from "react-native-svg";
import {IconProps, DISABLED_COLOR} from "./";
import colors from "../colors.json";

export default function AdobeFile(props: IconProps) {
  const color = props.disabled ? DISABLED_COLOR : props.color;

  return (
    <Svg height={props.size} width={props.size} viewBox="0 0 24 24" fill="none">
      <Path
        d="M21.207 4.5C21.3946 4.68749 21.4999 4.94181 21.5 5.207V22.5C21.5 22.7652 21.3946 23.0196 21.2071 23.2071C21.0196 23.3946 20.7652 23.5 20.5 23.5H3.5C3.23478 23.5 2.98043 23.3946 2.79289 23.2071C2.60536 23.0196 2.5 22.7652 2.5 22.5V1.5C2.5 1.23479 2.60536 0.980431 2.79289 0.792895C2.98043 0.605358 3.23478 0.500002 3.5 0.500002H16.793C17.0579 0.499527 17.3122 0.604185 17.5 0.791002L21.207 4.5Z"
        stroke={color || colors.black.DEFAULT}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M6.36097 6.88903C5.50997 5.48803 7.54597 4.20003 8.07797 5.63703C8.98897 8.10403 10.454 14.778 7.14897 19.314C6.22697 20.578 4.23797 19.381 5.30797 18.064C6.83997 16.2571 8.7502 14.8087 10.9036 13.8213C13.057 12.834 15.4011 12.3317 17.77 12.35C19.438 12.413 19.352 14.782 17.86 14.6C15.4784 14.2886 13.2012 13.4305 11.2064 12.0928C9.21153 10.7551 7.55318 8.97408 6.36097 6.88903V6.88903Z"
        stroke={color || colors.black.DEFAULT}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
