import * as React from "react";
import Svg, {Path} from "react-native-svg";
import {IconProps, DISABLED_COLOR} from ".";

import colors from "../colors.json";

export default function Members(props: IconProps) {
  const color = props.disabled ? DISABLED_COLOR : props.color;
  return (
    <Svg width={props.size} height={props.size} viewBox="0 0 22 20" fill="none">
      <Path
        d="M7.92309 7.69261C9.83484 7.69261 11.3846 6.14283 11.3846 4.23107C11.3846 2.31931 9.83484 0.769531 7.92309 0.769531C6.01133 0.769531 4.46155 2.31931 4.46155 4.23107C4.46155 6.14283 6.01133 7.69261 7.92309 7.69261Z"
        stroke={color || colors.gray[800]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M14.8462 19.2311H1V17.6926C1 15.8565 1.72939 14.0956 3.02772 12.7973C4.32605 11.4989 6.08696 10.7695 7.92308 10.7695C9.75919 10.7695 11.5201 11.4989 12.8184 12.7973C14.1168 14.0956 14.8462 15.8565 14.8462 17.6926V19.2311Z"
        stroke={color || colors.gray[800]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M14.0769 0.769531C14.995 0.769531 15.8754 1.13423 16.5246 1.78339C17.1737 2.43256 17.5384 3.31301 17.5384 4.23107C17.5384 5.14913 17.1737 6.02958 16.5246 6.67875C15.8754 7.32791 14.995 7.69261 14.0769 7.69261"
        stroke={color || colors.gray[800]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M16.5385 11.0618C17.849 11.5603 18.9772 12.445 19.7739 13.5988C20.5706 14.7526 20.9982 16.1212 21 17.5233V19.231H18.6923"
        stroke={color || colors.gray[800]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
