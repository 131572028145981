import {NextRouter} from "next/router";
import {Platform} from "react-native";
export type Router = NextRouter;

let routerHook: () => Router;
if (Platform.OS === "web") {
  // eslint-disable-next-line
  routerHook = require("next/router").useRouter;
}

export function useRouter(): Router {
  if (Platform.OS === "web") {
    return routerHook();
  }
  // XXX this isn't implemented in native world yet
  throw new Error("Native routing has not been implemented yet");
}
