import * as React from "react";
import Svg, {Path} from "react-native-svg";
import {IconProps, DISABLED_COLOR} from "./";
import colors from "../colors.json";

export default function Verified(props: IconProps) {
  const color = props.disabled ? DISABLED_COLOR : props.color;

  return (
    <Svg height={props.size} width={props.size} viewBox="0 0 20 24" fill="none">
      <Path
        d="M18.563 10.5731C17.638 20.4966 10.4 22.1709 10.019 22.2693C9.573 22.1547 -0.212995 19.8784 1.68001 4.73312L10.019 1.7326L17.919 4.55586L10.019 14.9615L7.08701 11.5329"
        stroke={color || colors.gray[800]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
