import * as React from "react";
import Svg, {Path} from "react-native-svg";
import {IconProps, DISABLED_COLOR} from ".";

import colors from "../colors.json";

export default function ClipboardIcon(props: IconProps) {
  const color = props.disabled ? DISABLED_COLOR : props.color;
  return (
    <Svg width={props.size} height={props.size} viewBox="0 0 13 11" fill="none">
      <Path
        d="M1 6.14286L4.47863 10L12 1"
        stroke={color || colors.green.dark}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
