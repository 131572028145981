import * as React from "react";
import Svg, {Path, Defs, ClipPath, Rect, G} from "react-native-svg";
import {IconProps, DISABLED_COLOR} from ".";

import colors from "../colors.json";

export default function Image(props: IconProps) {
  const color = props.disabled ? DISABLED_COLOR : props.color;
  return (
    <Svg height={props.size} width={props.size} viewBox="0 0 20 20" fill="none">
      <G clipPath="url(#clip0_2455:112)">
        <Path
          d="M5.71422 17.8572H17.8571C18.6461 17.8572 19.2856 17.2176 19.2856 16.4286V7.14289C19.2856 6.35391 18.6461 5.71432 17.8571 5.71432L5.71422 5.71432C4.92524 5.71432 4.28564 6.35391 4.28564 7.14289L4.28564 16.4286C4.28564 17.2176 4.92524 17.8572 5.71422 17.8572Z"
          stroke={color || colors.green.dark}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M0.714355 14.2857V3.57139C0.714355 3.19251 0.864865 2.82915 1.13277 2.56124C1.40068 2.29333 1.76405 2.14282 2.14293 2.14282H15.0001"
          stroke={color || colors.green.dark}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M5.14282 17.7428L10.7571 11.8143C10.8899 11.6683 11.0515 11.5515 11.2316 11.4711C11.4118 11.3907 11.6067 11.3485 11.804 11.3472C12.0013 11.3459 12.1967 11.3854 12.3779 11.4633C12.5592 11.5413 12.7223 11.6559 12.8571 11.8L18.5 17.7143"
          stroke={color || colors.green.dark}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_2455:112">
          <Rect height={props.size} width={props.size} fill="white" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}
