export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    Actionable: [
      "BookAppointmentActionable",
      "CompleteHPIActionable",
      "EducationalMaterialActionable",
      "FollowUpAppointmentActionable",
      "GenericActionable",
      "PayBillActionable",
      "ReferralActionable",
      "RxFillActionable",
    ],
    Connection: [
      "ActionablesConnection",
      "AppointmentsConnection",
      "BillExecutionJobsConnection",
      "BillsConnection",
      "CaseReportsConnection",
      "CovidHomeTestKitOrdersConnection",
      "CovidTestsConnection",
      "DocumentsConnection",
      "OrganizationConnection",
      "OrganizationGroupsConnection",
      "OrganizationMembersConnection",
      "PracticeAppointmentReasonConnection",
      "RuleExecutionConnection",
      "RulesConnection",
    ],
    MutationResponse: [
      "ApproveRuleResponse",
      "CompleteActionableResponse",
      "CreateRuleResponse",
      "DeleteRuleResponse",
      "DismissActionableResponse",
      "OrganizationChangeMembersResponse",
      "OrganizationMemberChangeResponse",
      "SnoozeActionableResponse",
      "UpdateRuleResponse",
    ],
    Node: [
      "Appointment",
      "AppointmentReason",
      "ApptReason",
      "Bill",
      "BillExecutionJob",
      "BillingUser",
      "BookAppointmentActionable",
      "Case",
      "CaseReport",
      "CompleteHPIActionable",
      "CovidHomeTestKitOrder",
      "CovidTest",
      "Document",
      "EducationalMaterialActionable",
      "FollowUpAppointmentActionable",
      "GenericActionable",
      "Image",
      "InPersonFollowUp",
      "MDEvent",
      "Material",
      "Organization",
      "OrganizationAdminUser",
      "OrganizationGroup",
      "OrganizationMember",
      "Patient",
      "PatientUser",
      "PayBillActionable",
      "PracticeAppointmentReason",
      "PracticeAppointmentReasonSpecialty",
      "ProviderUser",
      "ReferralActionable",
      "ReferralSuggestion",
      "Rule",
      "RuleExecution",
      "RxFillActionable",
      "Trail",
      "TreatmentPlan",
    ],
    User: ["OrganizationAdminUser"],
    UserBoundary: [
      "AuthenticationError",
      "BillingUser",
      "OrganizationAdminUser",
      "PatientUser",
      "ProviderUser",
    ],
    VaccinationConsentBoundary: ["ConsentError", "CovidVaccination"],
  },
};
export default result;
