import * as React from "react";
import Svg, {Circle, Path} from "react-native-svg";
import {IconProps, DISABLED_COLOR} from "./";
import colors from "../colors.json";

export default function Failure(props: IconProps) {
  const color = props.disabled ? DISABLED_COLOR : props.color;

  return (
    <Svg height={props.size} width={props.size} viewBox="0 0 32 29" fill="none">
      <Path
        d="M16 10L16 17"
        stroke={color || colors.gray[800]}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <Circle cx="16" cy="21" r="1" fill={color || colors.gray[800]} />
      <Path
        d="M14.8468 1.91581C15.3593 1.02806 16.6407 1.02806 17.1532 1.91581L30.5677 25.1504C31.0803 26.0382 30.4396 27.1479 29.4145 27.1479H2.58548C1.56039 27.1479 0.919712 26.0382 1.43226 25.1504L14.8468 1.91581Z"
        stroke={color || colors.gray[800]}
        strokeWidth="2.5"
      />
    </Svg>
  );
}
